<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">

        <div class="text-center p-4">
          <settings-trigger route-name="settings">
            <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" width="250" alt="JIRUGI">
          </settings-trigger>
        </div>

        <div class="text-center pt-5 pb-2">
          <h2>{{ t("umpire") }}</h2>
        </div>

        <div class="d-grid gap-2">
          <div class="btn-group" role="group" aria-label="Basic example">
            <router-link :to="{name: 'quick-login'}" class="btn btn-primary btn-lg">{{ t("quick_login") }}</router-link>
            <a class="btn btn-secondary btn-lg" href="http://jirugi.local:8082"><i class="fa-solid fa-right-from-bracket"></i> Local</a>
          </div>
        </div>

      <div class="divider p-3">
        {{ t("or") }}
      </div>

      <form @submit.prevent="login">

        <div v-if="data.errorMessage !== ''" class="alert alert-danger">
          {{ data.errorMessage }}
        </div>

        <div class="form-floating mb-3">
          <input type="text" class="form-control" id="login-email" v-model="data.email" :placeholder="t('email')">
          <label for="login-email">{{ t("email") }}</label>
        </div>

        <div class="form-floating mb-3">
          <input type="password" class="form-control" id="login-password" v-model="data.password" :placeholder="t('password')">
          <label for="login-password">{{ t("password") }}</label>
        </div>

        <div class="d-grid gap-2">
          <button type="submit" :class="{'is-loading': data.loading}" class="btn btn-primary btn-lg">
            {{ t("login") }}
          </button>
        </div>

      </form>

    </div>
  </div>
  </div>

</template>

<script setup>
import axios from "axios";
import {onMounted, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import common from "@/common";
import SettingsTrigger from "@/Components/SettingsTrigger.vue";

const {t} = useI18n()
const router = useRouter()
const route = useRoute()
const data = reactive({
  selfUrl: SELF_URL,
  errorMessage: "",
  email: "",
  password: "",
  settingsCounter: 0,
})

function login() {
  axios.post(common.jirugiApi() + "/v1/login", {
    email: data.email,
    password: data.password,
  }).then(resp => {
    if (resp.data.token === undefined) {
      data.errorMessage = "Something happened. Try again later."
      return
    }
    sessionStorage.setItem("accessToken", resp.data.token)
    sessionStorage.setItem("email", resp.data.email)
    axios.defaults.headers.post['Authorization'] = `Bearer ${sessionStorage.getItem('accessToken')}`;
    if (typeof js_bridge !== 'undefined') {
      js_bridge.storageSet("accessToken", resp.data.token)
    }
    router.push({name: "home"})
  }).catch(error => {
    if (error.response.status === 401) {
      data.errorMessage = "Wrong credentials"
    } else {
      data.errorMessage = "Something happened. Try again later."
    }
  })
}
</script>
