<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6 offset-md-3">

        <div class="text-center p-4">
          <settings-trigger route-name="settings">
            <img :src="data.selfUrl + '/static/jirugi_logo_light.svg'" width="200" alt="JIRUGI">
          </settings-trigger>
          <h2>{{ data.competition.name }}</h2>
          <h4>{{ data.email }}</h4>
        </div>

        <div class="d-grid gap-2">
          <button @click.prevent="set(data.latestUsedRingPosition, data.latestUsedRingId)" class="btn btn-lg btn-outline-info mb-5">
            {{ t('latest_used') }} ({{ data.latestUsedRingPosition }}@{{ data.latestUsedRingId }})
          </button>
        </div>

        <div v-for="ring in data.rings" class="pb-4">
          <h4 :class="{'current-ring': data.latestUsedRingId == ring.id}">{{ t("ring") }} {{ ring.name }} ({{ ring.id }})</h4>
          <div class="row">
            <div class="col-md-6">
              <button @click.prevent="set('jury', ring.id)" class="btn btn-primary btn-lg">
                {{ t("jury") }}
              </button>
              <!--<button @click.prevent="set('corner', ring.id)" class="btn btn-primary btn-lg">
                {{ t("corner") }}
              </button>-->
              <button @click.prevent="set('sparring', ring.id)" class="btn btn-primary btn-lg">
                {{ t("sparring") }}
              </button>
              <button @click.prevent="set('pattern', ring.id)" class="btn btn-primary btn-lg">
                {{ t("pattern") }}
              </button>
            </div>
          </div>

          <hr>

        </div>

        <div v-if="data.superUmpireData !== ''" class="d-grid gap-2">
          <button @click.prevent="set('', '')" class="btn btn-danger">{{ t("reset") }} ({{ data.superUmpireData }})</button>
        </div>

        <div class="pt-2">
          <router-link :to="{name: 'home'}" class="btn btn-secondary pt-2"><i class="fa-solid fa-chevron-left"></i>{{ t("back") }}</router-link>
        </div>

      </div>
    </div>
  </div>

</template>

<script setup>
import {onMounted, reactive} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import common from "@/common";
import axios from "axios";
import Settings from "@/Views/Settings.vue";
import SettingsTrigger from "@/Components/SettingsTrigger.vue";

const {t} = useI18n()
const route = useRoute()
const router = useRouter()
const data = reactive({
  selfUrl: SELF_URL,
  key: route.params.key,
  competition: {},
  rings: [],
  superUmpireData: '',
  email: sessionStorage.getItem("email"),

  latestUsedRingId: '',
  latestUsedRingPosition: ''
})

function set(position, ringId) {
  let p = position
  if (position === "sparring" || position === "pattern") {
    p = "corner"
  }
  axios.post(common.jirugiApi() + "/v1/set_super_umpire_info", {
    key: route.params.key,
    'ring_position': p,
    'ring_id': ringId
  }).then(resp => {
    sessionStorage.setItem('latestUsedRingId', ringId)
    sessionStorage.setItem('latestUsedRingPosition', position)
    if (position === "corner") {
      router.push({name: 'corner', params: {key: route.params.key, ringId: ringId}}) // TODO: this is the dynamic corner.
    } else if (position === "sparring") {
      router.push({name: 'sparring', params: {key: route.params.key, ringId: ringId}})
    } else if (position === "pattern") {
      router.push({name: 'pattern', params: {key: route.params.key, ringId: ringId}})
    } else if (position === "jury") {
      router.push({name: 'jury', params: {key: route.params.key, ringId: ringId}})
    } else if (position === "") {
      data.superUmpireData = ''
    } else {
      console.log("position not implemented.")
    }
  })
}

onMounted(() => {
  axios.post(common.jirugiApi() + "/v1/get_super_umpire_info", {key: route.params.key}).then(resp => {
    console.log(resp.data)
    if (resp.data.competition === null) {
      router.push({name: "home"})
      return
    }
    data.competition = resp.data.competition
    data.rings = resp.data.rings
    data.superUmpireData = resp.data.super_umpire_data
    data.latestUsedRingId = sessionStorage.getItem('latestUsedRingId')
    data.latestUsedRingPosition = sessionStorage.getItem('latestUsedRingPosition')
  })
})

</script>

<style>
.current-ring {
  color: darkgreen;
}
</style>
